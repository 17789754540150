import React from 'react';
import Layout from "../../../components/Layout"
import SEO from "../../../components/seo";
import Subheader from '../../../components/Header/navmap/fleet';
import sitemap from '../../../data/sitemap';
import Hero from './Hero';
import Why from './Why';
import Fleet from './Fleet';
import DriverApp from './DriverApp';
import Savings from './Savings';
import UsedBy from './UsedBy';
import Sponsors from './Sponsors';
import CallToAction from '../../CallToAction';
import Advantages from './Advantages';
import CaseStudies from './CaseStudies';
import RoiFleet from './RoiFleet';
import { locale } from '../../../locale';

export default ({ seo, location }) => (
  <Layout overlay page={sitemap.industries}
    subpage={sitemap.fleet._}
    subheader={Subheader}
    url='_url:fleet-management'>
    <SEO {...seo} location={location}/>
    <Hero/>
    <Why/>
    <Advantages/>
    <Fleet/>
    <DriverApp/>
    <Savings/>
    <UsedBy/>
    <Sponsors/>
    { locale === 'da'
      ? <CaseStudies/>
      : null
    }
    { locale === 'da'
      ? <RoiFleet/>
      : null
    }
    <CallToAction/>
  </Layout>
);