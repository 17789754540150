import React from 'react';
import clsx from 'clsx';
import { Grid } from '@material-ui/core';
import Img from 'gatsby-image/withIEPolyfill';
import { Fade } from 'react-reveal'; 
import styles from './styles.module.sass';

export default ({
  fluid,
  delay,
  alt,
  layout = { md: 3 }
}) => (
  <Grid item container {...layout} className={styles.fadeImageWrapper}>
    <Fade bottom delay={delay}>
      <Img objectFit='contain'
        objectPosition='center bottom'
        className={clsx(
          styles.image,
          styles.imageWrap
        )}
        alt={alt}
        fluid={fluid}/>
    </Fade>
  </Grid>
);